import getFirebase from "./firebase"
import { message } from "antd"

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const checkImage = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!")
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!")
  }
  return isJpgOrPng && isLt2M
}

export const upload = ({ onError, onSuccess, file }, folder) => {
  const firebase = getFirebase()
  var storageRef = firebase.storage().ref()
  var imagesRef = storageRef.child(folder)
  var fileName = new Date().getTime().toString()
  var fileRef = imagesRef.child(fileName)
  fileRef
    .put(file)
    .then(function (snapshot) {
      fileRef.getDownloadURL().then(function (url) {
        onSuccess(null, url)
      })
    })
    .catch(function (err) {
      onError(err)
    })
}

export const saveItem = (item, nameCollection, setModal, cleanState) => {
  const firebase = getFirebase()
  firebase
    .firestore()
    .collection(nameCollection)
    .add(item)
    .then((doc) => {
      cleanState && cleanState()
      setModal(false) //closeModal
    })
}

export const updateItem = (item, nameCollection, id, setModal) => {
  const firebase = getFirebase()
  firebase
    .firestore()
    .collection(nameCollection)
    .doc(id)
    .update(item)
    .then((doc) => {
      setModal(false) //closeModal
    })
}

export const collectionExists = async (nameCollection) => {
  const firebase = getFirebase()
  let info = null
  await firebase
    .firestore()
    .collection(nameCollection)
    .limit(1)
    .get()
    .then((data) => (info = data) /*docs[0].id*/)
  return info
}

export const login = async (email, password) => {
  const firebase = getFirebase()
  try {
    const user = await firebase.auth().signInWithEmailAndPassword(email, password);
    return user;
  } catch (error) {
    throw {
      code: error.code,
      message: error.message,
    };
  }
};