import React from "react"
import { Menu, Layout } from "antd"
import styles from "../../styles/dashboard-drawer.module.scss"
const { Sider } = Layout
// const { SubMenu } = Menu

const DashboardDrawer = ({ setItemOpen }) => {
  const handleClick = (e) => {
    setItemOpen(e.key)
  }

  return (
    <Sider
      breakpoint="md"
      collapsedWidth="0"
      className={styles.drawer}
      width={200}
      style={{ background: "#fff" }}
    >
      <Menu
        onClick={handleClick}
        mode="inline"
        defaultSelectedKeys={["Novedades"]}
        style={{ height: "100%" }}
      >
        <Menu.Item key="Novedades">Novedades</Menu.Item>
        <Menu.Item key="Experiencias">Experiencias</Menu.Item>
        {/* <SubMenu title="Experiencias">
          <Menu.Item key="ExperienciasPrincipal">Textos principales</Menu.Item>
          <Menu.Item key="Experiencias">Tarjetas y páginas</Menu.Item>
        </SubMenu> */}
        <Menu.Item key="Campañas">Campañas</Menu.Item>
      </Menu>
    </Sider>
  )
}

export default DashboardDrawer
