import React, { useState, useEffect } from "react"
import { List } from "antd"
import DashboardCarouselSingleItem from "./dashboard-carousel-single-item.jsx"
import DashboardCampaignsSingleItem from "./dashboard-campaigns-single-item.jsx"
import DashboardExperienceSingleItem from "./dashboard-experiences-single-item.jsx"
import DashboardExperienceMain from "./dashboard-experiences-main.jsx"
import {
  getCollectionImages,
  getCollectionImagesPositions,
} from "../../utils/functionsList"
import styles from "../../styles/dashboard-list.module.scss"

const DashboardListWithImages = ({
  setSelectedItem = null,
  setModalEdit = null,
  item = null,
  currentCategoryData,
  setCurrentCategoryData,
}) => {
  const [nameCollection, setNameCollection] = useState("")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setCurrentCategoryData([])
    setLoading(true)
    setNameCollection(item)
  }, [item])

  useEffect(() => {
    nameCollection === "Novedades" &&
      getCollectionImagesPositions(item, setCurrentCategoryData, setLoading)
    nameCollection === "Experiencias" &&
      getCollectionImagesPositions(item, setCurrentCategoryData, setLoading)
    nameCollection === "Campañas" &&
      getCollectionImages(item, setCurrentCategoryData, setLoading)
    nameCollection === "ExperienciasPrincipal" &&
      getCollectionImages(item, setCurrentCategoryData, setLoading)
  }, [nameCollection])

  return (
    <List
      className={styles.shopsTable}
      itemLayout="vertical"
      size="large"
      loading={loading}
      pagination={false}
      dataSource={currentCategoryData}
      renderItem={(item) =>
        nameCollection === "Novedades" ? (
          <DashboardCarouselSingleItem
            item={item}
            nameCollection={nameCollection}
            setSelectedItem={setSelectedItem}
            setModalEdit={setModalEdit}
          />
        ) : nameCollection === "Campañas" ? (
          <DashboardCampaignsSingleItem
            item={item}
            nameCollection={nameCollection}
            setSelectedItem={setSelectedItem}
            setModalEdit={setModalEdit}
          />
        ) : nameCollection === "Experiencias" ? (
          <DashboardExperienceSingleItem
            item={item}
            nameCollection={nameCollection}
            setSelectedItem={setSelectedItem}
            setModalEdit={setModalEdit}
          />
        ) : (
          <DashboardExperienceMain
            item={item}
            nameCollection={nameCollection}
            setSelectedItem={setSelectedItem}
            setModalEdit={setModalEdit}
          />
        )
      }
    />
  )
}

export default DashboardListWithImages
