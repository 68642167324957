import React, { useState, useEffect } from "react"
import {
  Input,
  Form,
  Select,
  // Upload, Modal,
  Switch,
  Button,
} from "antd"
// import { PlusOutlined } from "@ant-design/icons"
import {
  updateItem,
  // customUpload,
  // loadInfoImage,
} from "../../utils/functionsList"
import "../../styles/dashboard-edit-or-new-item.css"

const { Option } = Select
// const { TextArea } = Input

const DashboardCampaignsSingleItemEdit = ({
  selectedItem,
  setModal,
  item,
  subItem,
  currentCategoryData,
}) => {
  const [title, setTitle] = useState("")
  // const [text, setText] = useState("")
  // const [overline, setOverline] = useState("")
  const [video, setVideo] = useState("")
  const [position, setPosition] = useState("")
  const [active, setActive] = useState(true)

  // const [infoImage, setInfoImage] = useState([])
  // const [previewImage, setPreviewImage] = useState("")
  // const [visibleImagePreview, setVisibleImagePreview] = useState(false)

  const [nameCollection, setNameCollection] = useState("")
  const [error, setError] = useState("")
  const [formDisabled, setFormDisabled] = useState(true)

  const previewPosition = currentCategoryData.map((item, index) => {
    return { value: index + 1, label: "Posición " + (index + 1) }
  })

  useEffect(() => {
    loadData(selectedItem)
    setFormDisabled(true)
  }, [selectedItem])

  useEffect(() => {
    validateSubmit()
  }, [
    title,
    // text, overline,
    position,
    video,
    // infoImage,
  ])

  const loadData = (element) => {
    // const dataImage = JSON.parse(element.infoImage)
    setTitle(element.title)
    // setText(element.text)
    // setOverline(element.overline)
    setPosition(element.position)
    setVideo(element.video)
    setActive(element.active)
    setNameCollection(item)
    //allow preview image
    // setInfoImage([dataImage])
  }

  const validateSubmit = () => {
    if (
      title &&
      position &&
      video
      // && infoImage.length > 0
    ) {
      setFormDisabled(false)
    } else {
      setFormDisabled(true)
    }
  }

  const onEdit = () => {
    const obj = {
      id: selectedItem.id,
      title,
      // text,
      // overline,
      active,
      position,
      video,
      // image: infoImage[0].xhr,
      // infoImage: JSON.stringify(infoImage[0]),
    }
    updateItem(obj, nameCollection, setModal)
  }

  // upload images
  // const handlerImage = ({ file }) => {
  //   if (file.status === "removed") {
  //     // remove
  //     setInfoImage([])
  //   } else {
  //     //load image
  //     loadInfoImage(file, setInfoImage, setError)
  //   }
  // }

  // const handlePreview = async (file) => {
  //   setPreviewImage(file.xhr)
  //   setVisibleImagePreview(true)
  // }

  return (
    <div>
      <div className={"pageHeader modalHeader"}>
        <span className={"title"}>Editar campaña</span>
      </div>

      <div className={"modalRow"}>
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>TITULO *</p>
          <p className={"modalInputSubLabel"}>
            Esté título aparecerá en la página y también se usará para generar
            el URL de la campaña
          </p>
          <Form.Item>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          </Form.Item>
        </div>
        <div className={"modalInputContainer lastInput"}>
          <p className={"modalInputLabel"}>LINK DEL VIDEO</p>
          <Form.Item>
            <Input value={video} onChange={(e) => setVideo(e.target.value)} />
          </Form.Item>
        </div>
      </div>

      {/* <div className={"modalRow"}>
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>SOBRETÍTULO</p>
          <Form.Item>
            <Input
              value={overline}
              onChange={(e) => setOverline(e.target.value)}
            />
          </Form.Item>
        </div>
        <div className={"modalInputContainer lastInput"}>
          <p className={"modalInputLabel"}>PÁRRAFO</p>
          <Form.Item>
            <TextArea
              value={text}
              rows={4}
              onChange={(e) => setText(e.target.value)}
            />
          </Form.Item>
        </div>
      </div> */}

      <div className={"modalRow"}>
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>POSICIÓN *</p>
          <Form.Item>
            <Select
              style={{ width: "100%" }}
              placeholder="Seleccioná una opción"
              value={"Posición " + position}
              onChange={(e) => setPosition(e)}
            >
              <Option disabled value={""}>
                {"Seleccioná una opción"}
              </Option>
              {previewPosition.map((element, id) => {
                return (
                  <Option key={id} value={element.value}>
                    {element.label}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </div>
        <div className={"modalInputContainer lastInput"}>
          <Form.Item>
            <p className={"modalInputLabel"}>VISIBLE EN HOME</p>
            <p className={"modalInputSubLabel"}>
              Si se desactiva esta opción, la campaña sólo será visible a través
              de su link individual
            </p>
            <Switch
              checked={active}
              onChange={() => {
                setActive(!active)
              }}
            />
          </Form.Item>
        </div>
        {/* <div className={"modalInputContainer lastInput"}>
          <p className={"modalInputLabel"}>IMAGEN *</p>
          <Form.Item>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              fileList={infoImage}
              onChange={(e) => handlerImage(e)}
              customRequest={(e) => customUpload(e, "Campañas")}
              onPreview={handlePreview}
            >
              {!infoImage.length > 0 && <PlusOutlined />}
            </Upload>
          </Form.Item>
        </div> */}

        {/* <Modal
          wrapClassName={"wrapContainerModalPreview"}
          visible={visibleImagePreview}
          footer={null}
          onCancel={(e) => setVisibleImagePreview(false)}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal> */}
      </div>

      <div className={"ContainerMessageCantSlides"}>
        <p className={"messageCantSlide"}>{error}</p>
      </div>

      <Button
        onClick={onEdit}
        disabled={formDisabled}
        className={"sendButton"}
        type="primary"
        block
      >
        EDITAR
      </Button>
    </div>
  )
}

export default DashboardCampaignsSingleItemEdit
