import React, { useState, useEffect } from "react"
import {
  Input,
  Form,
  // Upload, Modal,
  Switch,
  Select,
  Button,
} from "antd"
// import { PlusOutlined } from "@ant-design/icons"
import {
  // getBase64, upload,
  saveItem,
} from "../../utils/functionsNew"
// import { beforeUpload } from "../../utils/functionsList"
import "../../styles/dashboard-edit-or-new-item.css"
const { Option } = Select

const DashboardNewCampaignsSingleItem = ({
  visible,
  item,
  setModal,
  currentCategoryData,
}) => {
  const [title, setTitle] = useState("")
  // const [text, setText] = useState("")
  // const [overline, setOverline] = useState("")
  const [video, setVideo] = useState("")
  const [position, setPosition] = useState("")
  const [active, setActive] = useState(true)

  const [nameCollection, setNameCollection] = useState(null)
  // const [error, setError] = useState("")
  const [formDisabled, setFormDisabled] = useState(true)

  // const [image, setImage] = useState(null)
  // const [infoImage, setInfoImage] = useState([])
  // const [previewImage, setPreviewImage] = useState("")
  // const [visibleImagePreview, setVisibleImagePreview] = useState(false)

  const previewPosition = [...currentCategoryData, null].map((item, index) => {
    return { value: index + 1, label: "Posición " + (index + 1) }
  })

  // const { TextArea } = Input

  useEffect(() => {
    setNameCollection(item)
  }, [visible])

  useEffect(() => {
    FormDisabledForm()
  }, [
    title,
    position,
    //  text, image, overline,
    video,
  ])

  const FormDisabledForm = () => {
    if (
      title &&
      position &&
      video
      // && image
    ) {
      setFormDisabled(false)
    } else {
      setFormDisabled(true)
    }
  }

  const cleanState = () => {
    setTitle("")
    // setText("")
    // setOverline("")
    setPosition("")
    setVideo("")
    setActive(true)
    // setImage(null)
    // setInfoImage({})
    // setPreviewImage(null)
  }

  const onSend = () => {
    const obj = {
      title,
      // text,
      // overline,
      active,
      position,
      video,
      // image: image,
      // infoImage: JSON.stringify(infoImage),
    }
    saveItem(obj, nameCollection, setModal, cleanState)
  }

  // const addImage = ({ file, fileList }) => {
  //   if (fileList.length > 0) {
  //     if (beforeUpload(file)) {
  //       setImage(file.xhr)
  //       setInfoImage(file)
  //       setError("")
  //     } else {
  //       setError("La imagen cargada es mayor a 2MB")
  //       setPreviewImage(null)
  //     }
  //   }
  // }

  // const handleImagePreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj)
  //   }

  //   if (file.url) {
  //     setPreviewImage(file.url)
  //   } else {
  //     setPreviewImage(file.preview)
  //   }

  //   setVisibleImagePreview(true)
  // }

  // const cancelPreview = () => {
  //   setVisibleImagePreview(false)
  // }

  // const handleRemoveImage = () => {
  //   setImage(null)
  //   setInfoImage(null)
  //   setPreviewImage(null)
  //   setVisibleImagePreview(null)
  // }

  return (
    <div>
      <div className={"pageHeader modalHeader"}>
        <span className={"title"}>Agregar una campaña</span>
      </div>

      <div className={"modalRow"}>
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>TITULO *</p>
          <p className={"modalInputSubLabel"}>
            Esté título aparecerá en la página y también se usará para generar
            el URL de la campaña
          </p>
          <Form.Item>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          </Form.Item>
        </div>
        <div className={"modalInputContainer lastInput"}>
          <p className={"modalInputLabel"}>LINK DEL VIDEO</p>
          <Form.Item>
            <Input value={video} onChange={(e) => setVideo(e.target.value)} />
          </Form.Item>
        </div>
      </div>

      {/* <div className={"modalRow"}>
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>SOBRETÍTULO</p>
          <Form.Item>
            <Input
              value={overline}
              onChange={(e) => setOverline(e.target.value)}
            />
          </Form.Item>
        </div>
        <div className={"modalInputContainer lastInput"}>
          <p className={"modalInputLabel"}>PÁRRAFO</p>
          <Form.Item>
            <TextArea
              value={text}
              rows={4}
              onChange={(e) => setText(e.target.value)}
            />
          </Form.Item>
        </div>
      </div> */}
      <div className={"modalRow"}>
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>POSICIÓN *</p>
          <Form.Item>
            <Select
              style={{ width: "100%" }}
              placeholder="Seleccioná una opción"
              value={"Posición " + position}
              onChange={(e) => setPosition(e)}
            >
              <Option disabled value={""}>
                {"Seleccioná una opción"}
              </Option>
              {previewPosition.map((element, id) => {
                return (
                  <Option key={id} value={element.value}>
                    {element.label}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </div>
        <div className={"modalInputContainer lastInput"}>
          <Form.Item>
            <p className={"modalInputLabel"}>VISIBLE EN HOME</p>
            <p className={"modalInputSubLabel"}>
              Si se desactiva esta opción, la campaña sólo será visible a través
              de su link individual
            </p>
            <Switch
              checked={active}
              onChange={() => {
                setActive(!active)
              }}
            />
          </Form.Item>
        </div>
        {/* <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>IMAGEN</p>
          <Form.Item>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              onPreview={handleImagePreview}
              onRemove={handleRemoveImage}
              customRequest={(e) => upload(e, "Campañas")}
              onChange={addImage}
            >
              {!image && <PlusOutlined />}
            </Upload>
            <Modal
              wrapClassName={"wrapContainerModalPreview"}
              visible={visibleImagePreview}
              footer={null}
              onCancel={cancelPreview}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
            </Modal>
          </Form.Item>
        </div> */}
      </div>

      {/* <div className={"ContainerMessageCantSlides"}>
        <p className={"messageCantSlide"}>{error}</p>
      </div> */}

      <Button
        onClick={onSend}
        disabled={formDisabled}
        className={"sendButton"}
        type="primary"
        block
      >
        CREAR
      </Button>
    </div>
  )
}

export default DashboardNewCampaignsSingleItem
