import React from "react"
import { List, Popconfirm } from "antd"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { deleteItem, editItem } from "../../utils/functionsList"
import styles from "../../styles/dashboard-list.module.scss"

const DashboardCarouselSingleItem = ({
  item,
  nameCollection,
  setSelectedItem,
  setModalEdit,
}) => {
  const IconText = ({ type, text, item }) => (
    <span onClick={() => editItem(item, setSelectedItem, setModalEdit)}>
      {type === "edit" && <EditOutlined style={{ marginRight: 8 }} />}
      {text}
    </span>
  )

  function cancel(e) {
    console.log(e)
  }

  const IconTextDelete = ({ type, text, item }) => (
    <Popconfirm
      title="Eliminar novedad?"
      onConfirm={() => deleteItem(item, nameCollection)}
      onCancel={cancel}
      okText="Si"
      cancelText="No"
    >
      <span>
        {type === "delete" && <DeleteOutlined style={{ marginRight: 8 }} />}
        {text}{" "}
      </span>
    </Popconfirm>
  )

  return (
    <List.Item
      className={styles.ItemLink}
      key={item.id}
      actions={[
        <IconText
          item={item}
          type="edit"
          text="Editar"
          key="list-vertical-edit"
        />,
        <IconTextDelete
          item={item}
          type="delete"
          text="Eliminar"
          key="list-vertical-delete"
        />,
      ]}
    >
      <div>
        <p className={styles.nameDescription}>
          Titulo: <span>{item.title}</span>{" "}
        </p>
        <p className={styles.nameDescription}>
          Párrafo: <span>{item.paragraph}</span>{" "}
        </p>
        <p className={styles.nameDescription}>
          Posición: <span>{item.position}</span>{" "}
        </p>
        <p className={styles.nameDescription}>
          Texto del botón: <span>{item.textButton}</span>{" "}
        </p>
        <p className={styles.nameDescription}>
          Link del botón: <span>{item.linkButton}</span>{" "}
        </p>
        <p className={styles.nameDescription}>
          Mostrar en la página: <span>{item.active ? "Sí" : "No"}</span>{" "}
        </p>
      </div>
      <div className={styles.ContainerImages}>
        <div className={styles.FirstElement}>
          <p>Imagen escritorio</p>
          <img
            className={styles.shopImageElement}
            alt="logo"
            src={item.imageDesktop}
          />
        </div>
        <div>
          <p>Imagen móvil</p>
          <img
            className={styles.shopImageElement}
            alt="logo"
            src={item.imageMobile}
          />
        </div>
      </div>
    </List.Item>
  )
}

export default DashboardCarouselSingleItem
