import React, { useState, useEffect } from "react"
import { Input, Form, Select, Upload, Modal, Button, Switch } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { updateItem, customUpload, loadInfoImage } from "../../utils/functionsList"
import "../../styles/dashboard-edit-or-new-item.css"

const { Option } = Select
const { TextArea } = Input

const DashboardCarouselSingleItemEdit = ({ selectedItem, setModal, item }) => {
  const [title, setTitle] = useState("")
  const [paragraph, setParagraph] = useState("")
  const [position, setPosition] = useState("")
  const [textButton, setTextButton] = useState("")
  const [linkButton, setLinkButton] = useState("")
  const [active, setActive] = useState(false)

  const [infoImageDesktop, setInfoImageDesktop] = useState([])
  const [infoImageMobile, setInfoImageMobile] = useState([])
  const [previewImage, setPreviewImage] = useState("")
  const [visibleImagePreview, setVisibleImagePreview] = useState(false)

  const [nameCollection, setNameCollection] = useState("")
  const [error, setError] = useState("")
  const [formDisabled, setFormDisabled] = useState(true)
  const previewPosition = [
    { value: 1, label: "Posición 1" },
    { value: 2, label: "Posición 2" },
    { value: 3, label: "Posición 3" },
    { value: 4, label: "Posición 4" },
    { value: 5, label: "Posición 5" },
  ]

  useEffect(() => {
    loadData(selectedItem)
    setFormDisabled(true)
  }, [selectedItem])

  useEffect(() => {
    validateSubmit()
  }, [
    title,
    paragraph,
    position,
    textButton,
    linkButton,
    active,
    infoImageDesktop,
    infoImageMobile,
  ])

  const loadData = (element) => {
    const dataImageDesktop = JSON.parse(element.infoImageDesktop)
    const dataImageMobile = JSON.parse(element.infoImageMobile)
    setTitle(element.title)
    setParagraph(element.paragraph)
    setPosition(element.position)
    setTextButton(element.textButton)
    setLinkButton(element.linkButton)
    setActive(element.active)
    setNameCollection(item)
    //allow preview image
    setInfoImageDesktop([dataImageDesktop])
    setInfoImageMobile([dataImageMobile])
  }

  const validateSubmit = () => {
    if (
      title &&
      position &&
      infoImageDesktop.length > 0 &&
      infoImageMobile.length > 0
    ) {
      setFormDisabled(false)
    } else {
      setFormDisabled(true)
    }
  }

  const onEdit = () => {
    const obj = {
      id: selectedItem.id,
      title,
      paragraph,
      position,
      textButton,
      linkButton,
      active,
      imageDesktop: infoImageDesktop[0].xhr,
      infoImageDesktop: JSON.stringify(infoImageDesktop[0]),
      imageMobile: infoImageMobile[0].xhr,
      infoImageMobile: JSON.stringify(infoImageMobile[0]),
    }
    updateItem(obj, nameCollection, setModal)
  }

  // upload images
  const handlerImage = ({ file }, type) => {
    if (file.status === "removed") {
      // remove

      if (type === "desktop") {
        setInfoImageDesktop([])
      } else {
        setInfoImageMobile([])
      }
    } else {
      //load image

      if (type === "desktop") {
        loadInfoImage(file, setInfoImageDesktop, setError)
      } else {
        loadInfoImage(file, setInfoImageMobile, setError)
      }
    }
  }

  const handlePreview = async (file) => {
    setPreviewImage(file.xhr)
    setVisibleImagePreview(true)
  }

  return (
    <div>
      <div className={"pageHeader modalHeader"}>
        <span className={"title"}>Editar novedad</span>
        {/* <p className={'modalErrorLabel'}>{this.state.errorLabel}</p> */}
      </div>

      {/* title and paragraph */}
      <div className={"modalRow"}>
        {/* title */}
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>TITULO *</p>
          <Form.Item>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          </Form.Item>
        </div>
        {/* paragraph */}
        <div className={"modalInputContainer lastInput"}>
          <p className={"modalInputLabel"}>PÁRRAFO</p>
          <Form.Item>
            <TextArea
              value={paragraph}
              rows={4}
              onChange={(e) => setParagraph(e.target.value)}
            />
          </Form.Item>
        </div>
      </div>

      {/* buttons */}
      <div className={"modalRow"}>
        {/* text button */}
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>TEXTO DEL BOTÓN</p>
          <Form.Item>
            <Input
              value={textButton}
              onChange={(e) => setTextButton(e.target.value)}
            />
          </Form.Item>
        </div>
        {/* link button */}
        <div className={"modalInputContainer lastInput"}>
          <p className={"modalInputLabel"}>LINK DEL BOTÓN</p>
          <Form.Item>
            <Input
              value={linkButton}
              onChange={(e) => setLinkButton(e.target.value)}
            />
          </Form.Item>
        </div>
      </div>

      {/* position and active */}
      <div className={"modalRow"}>
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>POSICIÓN *</p>
          <p className={"modalInputSubLabel"}>
            Posición de este item en el slider/carrusel
          </p>
          <Form.Item>
            <Select
              style={{ width: "100%" }}
              placeholder="Seleccioná una opción"
              value={"Posición " + position}
              onChange={(e) => setPosition(e)}
            >
              <Option disabled value={""}>
                {"Seleccioná una opción"}
              </Option>
              {previewPosition.map((element, id) => {
                return (
                  <Option key={id} value={element.value}>
                    {element.label}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </div>
        <div className={"modalInputContainer lastInput"}>
          <Form.Item>
            <p className={"modalInputLabel"}>ACTIVADO</p>
            <p className={"modalInputSubLabel"}>Mostrar u ocultar este item</p>
            <Switch
              checked={active}
              onChange={() => {
                setActive(!active)
              }}
            />
          </Form.Item>
        </div>
      </div>

      {/* images */}
      <div className={"modalRow"}>
        {/* imageDesktop */}
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>IMAGEN ESCRITORIO *</p>
          <Form.Item>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              fileList={infoImageDesktop}
              onChange={(e) => handlerImage(e, "desktop")}
              customRequest={(e) => customUpload(e, "Novedades/Desktop")}
              onPreview={handlePreview}
            >
              {!infoImageDesktop.length > 0 && <PlusOutlined />}
            </Upload>
          </Form.Item>
        </div>
        {/* imageMobile */}
        <div className={"modalInputContainer lastInput"}>
          <p className={"modalInputLabel"}>IMAGEN MÓVIL *</p>
          <Form.Item>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              fileList={infoImageMobile}
              onChange={(e) => handlerImage(e, "mobile")}
              customRequest={(e) => customUpload(e, "Novedades/Mobile")}
              onPreview={handlePreview}
            >
              {!infoImageMobile.length > 0 && <PlusOutlined />}
            </Upload>
          </Form.Item>
        </div>
        {/* modal view image preview */}
        <Modal
          wrapClassName={"wrapContainerModalPreview"}
          visible={visibleImagePreview}
          footer={null}
          onCancel={(e) => setVisibleImagePreview(false)}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>

      {/* error */}
      <div className={"ContainerMessageCantSlides"}>
        <p className={"messageCantSlide"}>{error}</p>
      </div>

      <Button
        onClick={onEdit}
        disabled={formDisabled}
        className={"sendButton"}
        type="primary"
        block
      >
        EDITAR
      </Button>
    </div>
  )
}

export default DashboardCarouselSingleItemEdit
