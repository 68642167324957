import React, { useState, useEffect } from "react"
import { Form, Input, Button } from "antd"
import { UserOutlined, LockOutlined } from "@ant-design/icons"
import {login} from "../../utils/functionsNew"
import styles from "../../styles/dashboard-login.module.scss"
import "../../styles/base/antd.css"
var pbkdf2 = require('pbkdf2')

const HorizontalLoginForm = ({ setLogin }) => {
  const [error, setError] = useState("")
  const [form] = Form.useForm()
  const { getFieldsError, getFieldError, isFieldTouched } = form

  // Only show error after a field is touched.
  const usernameError = isFieldTouched("username") && getFieldError("username")
  const passwordError = isFieldTouched("password") && getFieldError("password")

  const onFinish = async (values) => {
    var derivedKey = await pbkdf2.pbkdf2Sync( values.password, 'salt', 1, 32, 'sha256')
    let user = await login(values.username,derivedKey.toString('hex'))
    if (user.user.uid === "nXKWRP0Km3VMEsPKd2faDkvfz5y1") {
      setLogin(true)
    } else {
      setError("Error de autenticación")
    }
  }

  function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field])
  }

  // export const login = async ({ email, password }) => {
  //   try {
  //     const user = await firebase.auth().signInWithEmailAndPassword(email, password);
  //     return user;
  //   } catch (error) {
  //     throw {
  //       code: error.code,
  //       message: error.message,
  //     };
  //   }
  // };

  return (
    <Form
      form={form}
      className={styles.dashboardLoginForm}
      layout=""
      onFinish={onFinish}
      
    >
      <Form.Item
        validateStatus={usernameError ? "error" : ""}
        help={usernameError || ""}
        name="username"
        rules={[{ required: true, message: "Debe ingresar el usuario" }]}
      >
        <Input
          prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Username"
        />
      </Form.Item>
      <Form.Item
        validateStatus={passwordError ? "error" : ""}
        help={passwordError || ""}
        name="password"
        rules={[{ required: true, message: "Debe ingresar la contraseña" }]}
      >
        <Input
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={hasErrors(getFieldsError())}
        >
          Log in
        </Button>
      </Form.Item>
      <p style={{ color: "red" }}>{error}</p>
    </Form>
  )
}

// let Login = Form.create({ name: "horizontal_login" })(HorizontalLoginForm)

export default HorizontalLoginForm
