import React, { useState } from "react"
import { Breadcrumb, Button, Layout, Modal, PageHeader, message } from "antd"
import { MenuOutlined, CloseCircleOutlined } from "@ant-design/icons"

import styles from "../styles/dashboard.module.scss"
import logo from "../svg/footer-logo.svg"
import itesa from "../images/Dashboard/logo-itesa.png"

import DashboardLogin from "../components/dashboard/dashboard-login.jsx"
import DashboardDrawer from "../components/dashboard/dashboard-drawer.jsx"
import DashboardDrawerMobile from "../components/dashboard/dashboard-drawer-mobile.jsx"

import DashboardListWithImages from "../components/dashboard/dashboard-list-with-images.jsx"

import DashboardNewCarouselSingleItem from "../components/dashboard/dashboard-new-carousel-single-item.jsx"
import DashboardNewExperiencesSingleItem from "../components/dashboard/dashboard-new-experiences-single-item.jsx"
import DashboardNewCampaignsSingleItem from "../components/dashboard/dashboard-new-campaigns-single-item.jsx"

import DashboardCampaignsSingleItemEdit from "../components/dashboard/dashboard-campaigns-single-item-edit.jsx"
import DashboardCarouselSingleItemEdit from "../components/dashboard/dashboard-carousel-single-item-edit.jsx"
import DashboardExperiencesSingleItemEdit from "../components/dashboard/dashboard-experiences-single-item-edit.jsx"
import DashboardExperiencesMainEdit from "../components/dashboard/dashboard-experiences-main-edit.jsx"

const { Content } = Layout

const Dashboard = ({ data }) => {
  const [login, setLogin] = useState(false)

  const [itemOpen, setItemOpen] = useState("Novedades")

  const [currentCategoryData, setCurrentCategoryData] = useState([])

  // new
  const [
    modalNewCampaignsSingleItem,
    setModalNewCampaignsSingleItem,
  ] = useState(false)
  const [modalNewCarouselSingleItem, setModalNewCarouselSingleItem] = useState(
    false
  )
  const [
    modalNewExperiencesSingleItem,
    setModalNewExperiencesSingleItem,
  ] = useState(false)

  // edit
  const [
    modalCampaignsSingleItemEdit,
    setModalCampaignsSingleItemEdit,
  ] = useState(false)
  const [
    modalCarouselSingleItemEdit,
    setModalCarouselSingleItemEdit,
  ] = useState(false)
  const [
    modalExperiencesSingleItemEdit,
    setModalExperiencesSingleItemEdit,
  ] = useState(false)
  const [modalExperiencesMainEdit, setModalExperiencesMainEdit] = useState(
    false
  )

  // item selected
  const [selectedItem, setSelectedItem] = useState({})

  const [mobileOpen, setMobileOpen] = useState(false)

  const onClickNewButton = () => {
    if (itemOpen === "Campañas") {
      setModalNewCampaignsSingleItem(true)
    } else if (itemOpen === "Novedades") {
      setModalNewCarouselSingleItem(true)
    } else if (itemOpen === "Experiencias") {
      setModalNewExperiencesSingleItem(true)
    }
  }

  const getSetModal = () => {
    if (itemOpen === "Campañas") return setModalCampaignsSingleItemEdit
    else if (itemOpen === "Novedades") return setModalCarouselSingleItemEdit
    else if (itemOpen === "Experiencias")
      return setModalExperiencesSingleItemEdit
  }

  const handleDeploy = async () => {
    try {
      message.success("Actualización en progreso!")
      let response = await fetch(
        "https://us-central1-stellalanding.cloudfunctions.net/deploy",
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      if (response.ok) {
        message.success("Bien! En 10 minutos veras los cambios aplicados!")
      } else {
        message.warning("Ups! Algo malir sal, ponete en contacto con Agustín!")
      }
    } catch (error) {
      console.log(error)
      message.warning("Ups! Algo malir sal, ponete en contacto con Agustín!")
    }
  }

  return (
    <div className="App">
      {!login && <DashboardLogin setLogin={setLogin} />}
      {login && (
        <div className={styles.dashboardContainer}>
          <PageHeader
            className={styles.appBar}
            backIcon={false}
            ghost={false}
            onBack={() => null}
            title={
              <span>
                <Button
                  className={styles.hambButton}
                  onClick={() => setMobileOpen(!mobileOpen)}
                  type="primary"
                  ghost={true}
                  icon={<MenuOutlined />}
                  //   BACK UP: icon="menu"
                />{" "}
                <a target="__blank" href="https://www.stellaartois.com.ar/">
                  <img src={logo} className={styles.title} />
                </a>
              </span>
            }
            extra={
              <>
                <Button
                  className={styles.buttonUpdatePage}
                  onClick={handleDeploy}
                >
                  Actualizar página
                </Button>
                <a className={styles.itesaLogo} href={"https://itesa.co"}>
                  <img src={itesa} className={styles.title} />
                </a>
              </>
            }
          />
          <Layout style={{ backgroundColor: "#ffffff" }}>
            <DashboardDrawer setItemOpen={setItemOpen} />
            <DashboardDrawerMobile
              setItemOpen={setItemOpen}
              setMobileOpen={setMobileOpen}
              mobileOpen={mobileOpen}
            />
            <Content className={styles.content}>
              <Breadcrumb className={styles.breadcrumb}>
                <Breadcrumb.Item>
                  {itemOpen}
                  {/* {itemOpen === "ExperienciasPrincipal"
                    ? "Experiencias (textos principales)"
                    : itemOpen} */}
                </Breadcrumb.Item>
              </Breadcrumb>
              {/* <Breadcrumb className={styles.breadcrumb}>
                <Breadcrumb.Item>
                  {itemOpen === "Experiencias" ? "(tarjetas y páginas)" : null}
                </Breadcrumb.Item>
              </Breadcrumb> */}

              {/* {itemOpen !== "ExperienciasPrincipal" && ( */}
              <Button
                onClick={onClickNewButton}
                ghost={true}
                size={"large"}
                className={styles.newButton}
                type="primary"
              >
                NUEVO
              </Button>
              {/* )} */}

              <DashboardListWithImages
                setSelectedItem={setSelectedItem}
                setModalEdit={getSetModal()}
                item={itemOpen}
                currentCategoryData={currentCategoryData}
                setCurrentCategoryData={setCurrentCategoryData}
              />

              {/* {itemOpen === "ExperienciasPrincipal" && (
                <DashboardListWithImages
                  setSelectedItem={setSelectedItem}
                  setModalEdit={setModalExperiencesMainEdit}
                  item={itemOpen}
                />
              )} */}
            </Content>
          </Layout>

          {/*NEW ELEMENT */}
          <Modal
            centered
            closeIcon={<CloseCircleOutlined style={{ fontSize: "2rem" }} />}
            visible={modalNewCampaignsSingleItem}
            onOk={() => setModalNewCampaignsSingleItem(false)}
            onCancel={() => setModalNewCampaignsSingleItem(false)}
            footer={null}
            width={900}
            wrapClassName={"wrapContainerModal"}
          >
            <DashboardNewCampaignsSingleItem
              visible={modalNewCampaignsSingleItem}
              setModal={setModalNewCampaignsSingleItem}
              item={itemOpen}
              currentCategoryData={currentCategoryData}
            />
          </Modal>

          <Modal
            centered
            closeIcon={<CloseCircleOutlined style={{ fontSize: "2rem" }} />}
            visible={modalNewCarouselSingleItem}
            onOk={() => setModalNewCarouselSingleItem(false)}
            onCancel={() => setModalNewCarouselSingleItem(false)}
            footer={null}
            width={900}
            wrapClassName={"wrapContainerModal"}
          >
            <DashboardNewCarouselSingleItem
              visible={modalNewCarouselSingleItem}
              setModal={setModalNewCarouselSingleItem}
              item={itemOpen}
            />
          </Modal>

          <Modal
            centered
            closeIcon={<CloseCircleOutlined style={{ fontSize: "2rem" }} />}
            visible={modalNewExperiencesSingleItem}
            onOk={() => setModalNewExperiencesSingleItem(false)}
            onCancel={() => setModalNewExperiencesSingleItem(false)}
            footer={null}
            width={900}
            wrapClassName={"wrapContainerModal"}
          >
            <DashboardNewExperiencesSingleItem
              visible={modalNewCarouselSingleItem}
              setModal={setModalNewExperiencesSingleItem}
              item={itemOpen}
            />
          </Modal>

          {/* EDIT ELEMENT */}
          <Modal
            centered
            closeIcon={<CloseCircleOutlined style={{ fontSize: "2rem" }} />}
            visible={modalCampaignsSingleItemEdit}
            onOk={() => setModalCampaignsSingleItemEdit(false)}
            onCancel={() => setModalCampaignsSingleItemEdit(false)}
            footer={null}
            width={900}
            wrapClassName={"wrapContainerModal"}
          >
            <DashboardCampaignsSingleItemEdit
              selectedItem={selectedItem}
              visible={modalCampaignsSingleItemEdit}
              setModal={setModalCampaignsSingleItemEdit}
              item={itemOpen}
              currentCategoryData={currentCategoryData}
            />
          </Modal>
          <Modal
            centered
            closeIcon={<CloseCircleOutlined style={{ fontSize: "2rem" }} />}
            visible={modalCarouselSingleItemEdit}
            onOk={() => setModalCarouselSingleItemEdit(false)}
            onCancel={() => setModalCarouselSingleItemEdit(false)}
            footer={null}
            width={900}
            wrapClassName={"wrapContainerModal"}
          >
            <DashboardCarouselSingleItemEdit
              selectedItem={selectedItem}
              visible={modalCarouselSingleItemEdit}
              setModal={setModalCarouselSingleItemEdit}
              item={itemOpen}
            />
          </Modal>
          <Modal
            centered
            closeIcon={<CloseCircleOutlined style={{ fontSize: "2rem" }} />}
            visible={modalExperiencesSingleItemEdit}
            onOk={() => setModalExperiencesSingleItemEdit(false)}
            onCancel={() => setModalExperiencesSingleItemEdit(false)}
            footer={null}
            width={900}
            wrapClassName={"wrapContainerModal"}
          >
            <DashboardExperiencesSingleItemEdit
              selectedItem={selectedItem}
              visible={modalExperiencesSingleItemEdit}
              setModal={setModalExperiencesSingleItemEdit}
              item={itemOpen}
            />
          </Modal>
          <Modal
            centered
            closeIcon={<CloseCircleOutlined style={{ fontSize: "2rem" }} />}
            visible={modalExperiencesMainEdit}
            onOk={() => setModalExperiencesMainEdit(false)}
            onCancel={() => setModalExperiencesMainEdit(false)}
            footer={null}
            width={900}
            wrapClassName={"wrapContainerModal"}
          >
            <DashboardExperiencesMainEdit
              selectedItem={selectedItem}
              visible={modalExperiencesMainEdit}
              setModal={setModalExperiencesMainEdit}
              item={itemOpen}
            />
          </Modal>
        </div>
      )}
    </div>
  )
}

export default Dashboard
