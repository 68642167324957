import React from "react";
import { List, Popconfirm } from "antd";
import moment from "moment";
import "moment/locale/es";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { deleteItem, editItem } from "../../utils/functionsList";
import styles from "../../styles/dashboard-list.module.scss";

moment.locale("es");

const DashboardExperiencesingleItem = ({
    item,
    nameCollection,
    setSelectedItem,
    setModalEdit,
}) => {
    const IconText = ({ type, text, item }) => (
        <span onClick={() => editItem(item, setSelectedItem, setModalEdit)}>
            {type === "edit" && <EditOutlined style={{ marginRight: 8 }} />}
            {text}
        </span>
    );
    function cancel(e) {
        console.log(e);
    }

    const IconTextDelete = ({ type, text, item }) => (
        <Popconfirm
            title="Eliminar experiencia?"
            onConfirm={() => deleteItem(item, nameCollection)}
            onCancel={cancel}
            okText="Si"
            cancelText="No"
        >
            <span>
                {type === "delete" && (
                    <DeleteOutlined style={{ marginRight: 8 }} />
                )}
                {text}{" "}
            </span>
        </Popconfirm>
    );

    return (
        <List.Item
            className={styles.ItemLink}
            key={item.id}
            actions={[
                <IconText
                    item={item}
                    type="edit"
                    text="Editar"
                    key="list-vertical-edit"
                />,
                <IconTextDelete
                    item={item}
                    type="delete"
                    text="Eliminar"
                    key="list-vertical-delete"
                />,
            ]}
        >
            <div>
                <div className={styles.singleItemTitle}>
                    Tarjeta
                    <hr />
                </div>
                <br />
                <p className={styles.nameDescription}>
                    Titulo: <span>{item.cardTitle}</span>{" "}
                </p>
                {/* <p className={styles.nameDescription}>
          Texto: <span>{item.cardText}</span>{" "}
        </p>
        <p className={styles.nameDescription}>
          Sobretítulo: <span>{item.cardOverline}</span>{" "}
        </p> */}
                <p className={styles.nameDescription}>
                    Texto del botón: <span>{item.cardButtonText}</span>{" "}
                </p>
                <p className={styles.nameDescription}>
                    Posición: <span>{item.position}</span>{" "}
                </p>
                <p className={styles.nameDescription}>
                    Activada: <span>{item.cardActive ? "Sí" : "No"}</span>{" "}
                </p>

                <div className={styles.singleItemTitle}>
                    SCRIPT
                    <hr />
                </div>
                <p className={styles.nameDescription}>
                    Titulo de Campaña: <span>{item.scriptTitle}</span>{" "}
                </p>
                <p className={styles.nameDescription}>
                    ABI Insterest: <span>{item.scriptInterest}</span>{" "}
                </p>
                
                <div className={styles.singleItemTitle}>
                    Página
                    <hr />
                </div>
                <br />
                <p className={styles.nameDescription}>
                    Titulo: <span>{item.pageTitle}</span>{" "}
                </p>
                <p className={styles.nameDescription}>
                    Texto: <span>{item.pageText}</span>{" "}
                </p>
                <p className={styles.nameDescription}>
                    Ubicación: <span>{item.location}</span>{" "}
                </p>
                <p className={styles.nameDescription}>
                    Fecha y hora:{" "}
                    <span>
                        {item.date
                            ? moment(item.date).format(
                                  "D [de] MMMM [de] YYYY, h:mm a"
                              )
                            : ""}
                    </span>{" "}
                </p>
                {/* <p className={styles.nameDescription}>
          Sobretítulo: <span>{item.pageOverline}</span>{" "}
        </p> */}
                <p className={styles.nameDescription}>
                    Titulo del formulario: <span>{item.pageFormTitle}</span>{" "}
                </p>
                <p className={styles.nameDescription}>
                    Texto del formulario: <span>{item.pageFormText}</span>{" "}
                </p>
                <p className={styles.nameDescription}>
                    Activada: <span>{item.pageActive ? "Sí" : "No"}</span>{" "}
                </p>
                <p className={styles.nameDescription}>
                    Pregunta: <span>{item.question}</span>{" "}
                </p>
                {item.answer.length > 1 && item.answer[1]?.length > 0 ? (
                    <p className={styles.nameDescription}>
                        <ul>
                            {item.answer.map((ans, idx) => {
                                if (idx)
                                    return (
                                        <li className={styles.nameDescription}>
                                            {ans}
                                        </li>
                                    );
                            })}
                        </ul>
                    </p>
                ) : null}
                {!item.provinces.length ? (
                    <p className={styles.nameDescription}>
                        Provincias: Estan todas seleccionadas.
                    </p>
                ) : (
                    <p className={styles.nameDescription}>
                        Provincias:
                        <ul>
                            {item.provinces.map((province) => {
                                return (
                                    <li className={styles.nameDescription}>
                                        {province}
                                    </li>
                                );
                            })}
                        </ul>
                    </p>
                )}
                <p className={styles.nameDescription}>
                    Bases y condiciones:{" "}
                    <a href={item.bases} target="_blank" rel="noreferrer">
                        bases y condiciones
                    </a>{" "}
                </p>
            </div>
            <div className={styles.ContainerImages}>
                <div className={styles.FirstElement}>
                    <p>Imagen de la tarjeta</p>
                    <img
                        className={styles.shopImageElement}
                        alt="Imagen de la tarjeta"
                        src={item.cardImage}
                    />
                </div>
                <div>
                    <p>Imagen de la página</p>
                    <img
                        className={styles.shopImageElement}
                        alt="Imagen de la página"
                        src={item.pageImage}
                    />
                </div>
            </div>
        </List.Item>
    );
};

export default DashboardExperiencesingleItem;
