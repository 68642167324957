import getFirebase from "./firebase"
import { message } from "antd"

export const getCollectionImages = (nameCollection, setData, setLoading) => {
  const firebase = getFirebase()
  firebase
    .firestore()
    .collection(nameCollection)
    .onSnapshot(function (snap) {
      let arr = []
      setLoading(false)
      snap.forEach(function (item) {
        let itemData = item.data()
        itemData.id = item.id
        arr.push(itemData)
      })
      setData(arr)
    })
}

export const getCollectionImagesPositions = (
  nameCollection,
  setData,
  setLoading
) => {
  const firebase = getFirebase()
  firebase
    .firestore()
    .collection(nameCollection)
    .orderBy("position")
    .onSnapshot(function (snap) {
      let arr = []
      setLoading(false)
      snap.forEach(function (item) {
        let itemData = item.data()
        itemData.id = item.id
        arr.push(itemData)
      })
      setData(arr)
    })
}

export const countDocument = async (nameCollection, setCount) => {
  const firebase = getFirebase()
  await firebase
    .firestore()
    .collection(nameCollection)
    .get()
    .then((data) => setCount(data.size))
}

export const updateItem = (item, nameCollection, setModal) => {
  const firebase = getFirebase()
  firebase
    .firestore()
    .collection(nameCollection)
    .doc(item.id)
    .update(item)
    .then((doc) => {
      setModal(false)
    })
}

export const deleteItem = (item, nameCollection) => {
  const firebase = getFirebase()
  firebase
    .firestore()
    .collection(nameCollection)
    .doc(item.id)
    .delete()
    .then(() => message.success("Item Eliminado"))
    .catch((err) => console.log(err))
}

export const editItem = (item, setItem, setModal) => {
  setItem(item) //set item in main component to edit
  setModal(true) //allows to open modal to edit item
}

export const beforeUpload = (file) => {
  const type = file.type === "image/jpeg" || file.type === "image/png"
  if (!type) {
    message.error("You can only upload JPG/PNG file!")
    return false
  }
  const size = file.size / 1024 / 1024 < 2
  if (!size) {
    message.error("Image must smaller than 2MB!")
    return false
  }
  return true
}

export const beforeUpload2 = (file) => {
  // const type = file.type === "image/jpeg" || file.type === "image/png"
  // if (!type) {
  //   message.error("You can only upload JPG/PNG file!")
  //   return false
  // }
  const size = file.size / 1024 / 1024 < 2
  if (!size) {
    message.error("Image must smaller than 2MB!")
    return false
  }
  return true
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    console.log("file 64: ", file)
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const customUpload = (event, folder) => {
  const firebase = getFirebase()
  var imagesRef = firebase.storage().ref().child(folder)
  var fileName = new Date().getTime().toString()
  var fileRef = imagesRef.child(fileName)
  fileRef
    .put(event.file)
    .then(function (snapshot) {
      fileRef.getDownloadURL().then(function (url) {
        event.onSuccess(null, url)
      })
    })
    .catch(function (err) {
      event.onError(err)
    })
}

export const loadInfoImage = (file, setInfoImage, setError) => {
  if (file.status != "removed") {
    if (beforeUpload(file)) {
      //check type and size image
      setInfoImage([file])
      setError("")
    } else {
      setError("La imagen cargada es mayor a 2MB")
    }
  }
}

export const loadInfoFile = (file, setInfoFile, setError) => {
  if (file.status != "removed") {
    if (beforeUpload2(file)) {
      //check type and size image
      setInfoFile([file])
      setError("")
    } else {
      setError("La imagen cargada es mayor a 2MB")
    }
  }
}