import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/storage';

const config = {
  apiKey: "AIzaSyBtTMTqQlRrf8ENIcpwpkRM2onfUhbwejM",
  authDomain: "stellalanding.firebaseapp.com",
  projectId: "stellalanding",
  storageBucket: "stellalanding.appspot.com",
  messagingSenderId: "105711423116",
  appId: "1:105711423116:web:bdcd82d9844a0c5fc4f5a9"
};

let instance;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(config);
    return instance;
  }
  return null;
}