import React, { useState, useEffect } from "react";
import {
    Input,
    Form,
    Upload,
    Modal,
    Button,
    Select,
    Switch,
    Radio,
    Checkbox,
    DatePicker,
} from "antd";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import { getBase64, upload, saveItem } from "../../utils/functionsNew";
import {
    countDocument,
    beforeUpload,
    beforeUpload2,
} from "../../utils/functionsList";
import "../../styles/dashboard-edit-or-new-item.css";

const options = [
    { label: "Buenos Aires - CABA", value: "Buenos Aires - CABA" },
    {
        label: "Buenos Aires - Mar del Plata",
        value: "Buenos Aires - Mar del Plata",
    },
    { label: "Buenos Aires - Pinamar", value: "Buenos Aires - Pinamar" },
    { label: "Buenos Aires", value: "Buenos Aires" },

    { label: "Catamarca", value: "Catamarca" },
    { label: "Chaco", value: "Chaco" },
    { label: "Chubut", value: "Chubut" },
    {
        label: "Ciudad Autónoma de Buenos Aires",
        value: "Ciudad Autónoma de Buenos Aires",
    },
    { label: "Córdoba", value: "Córdoba" },
    { label: "Corrientes", value: "Corrientes" },
    { label: "Entre Ríos", value: "Entre Ríos" },
    { label: "Formosa", value: "Formosa" },
    { label: "Jujuy", value: "Jujuy" },
    { label: "La Pampa", value: "La Pampa" },
    { label: "La Plata", value: "La Plata" },
    { label: "La Rioja", value: "La Rioja" },
    { label: "Mendoza", value: "Mendoza" },
    { label: "Misiones", value: "Misiones" },
    { label: "Neuquén", value: "Neuquén" },
    { label: "Paraná", value: "Paraná" },
    { label: "Posadas", value: "Posadas" },
    { label: "Rawson", value: "Rawson" },
    { label: "Resistencia", value: "Resistencia" },
    
    { label: "Río Gallegos", value: "Río Gallegos" },
    { label: "Río Negro", value: "Río Negro" },
    { label: "Rosario", value: "Rosario" },
    { label: "Salta", value: "Salta" },
    { label: "San Carlos de Bariloche", value: "San Carlos de Bariloche" },
    {
        label: "San Fernando del Valle de Catamarca",
        value: "San Fernando del Valle de Catamarca",
    },
    { label: "San Juan", value: "San Juan" },
    { label: "San Luis", value: "San Luis" },
    { label: "San Martin de los Andes", value: "San Martin de los Andes" },
    { label: "San Miguel de Tucumán", value: "San Miguel de Tucumán" },
    { label: "San Salvador de Jujuy", value: "San Salvador de Jujuy" },
    { label: "Santa Cruz", value: "Santa Cruz" },
    { label: "Santa Fe", value: "Santa Fe" },
    { label: "Santa Rosa", value: "Santa Rosa" },
    { label: "Santiago del Estero", value: "Santiago del Estero" },
    { label: "Tierra del Fuego", value: "Tierra del Fuego" },
    { label: "Tucumán", value: "Tucumán" },
    { label: "Ushuaia", value: "Ushuaia" },
    { label: "Viedma", value: "Viedma" },
    { label: "Villa La Angostura", value: "Villa La Angostura" },
];

const DashboardNewExperiencesSingleItem = ({ visible, item, setModal }) => {
    const [cardTitle, setCardTitle] = useState("");
    const [scriptTitle, setScriptTitle] = useState("");
    const [scriptInterest, setScriptInterest] = useState("");
    // const [cardText, setCardText] = useState("")
    // const [cardOverline, setCardOverline] = useState("")
    const [cardButtonText, setCardButtonText] = useState("");
    const [cardActive, setCardActive] = useState(true);
    const [position, setPosition] = useState("");

    const [location, setLocation] = useState("");
    const [date, setDate] = useState("");

    const [pageTitle, setPageTitle] = useState("");
    const [pageText, setPageText] = useState("");
    // const [pageOverline, setPageOverline] = useState("")
    const [pageFormTitle, setPageFormTitle] = useState("");
    const [pageFormText, setPageFormText] = useState("");
    const [pageActive, setPageActive] = useState(true);

    const [formDisabled, setFormDisabled] = useState(true);
    const [nameCollection, setNameCollection] = useState(null);
    const [itemQuantity, setItemQuantity] = useState(null);
    const [error, setError] = useState("");

    // card image
    const [cardImage, setCardImage] = useState(null);
    const [cardImageInfo, setCardImageInfo] = useState({});
    const [cardImagePreview, setCardImagePreview] = useState(null);
    const [cardImageVisiblePreview, setCardImageVisiblePreview] = useState(
        null
    );
    // page image
    const [pageImage, setPageImage] = useState(null);
    const [pageImageInfo, setPageImageInfo] = useState({});
    const [pageImagePreview, setPageImagePreview] = useState(null);
    const [pageImageVisiblePreview, setPageImageVisiblePreview] = useState(
        null
    );

    const [bases, setBases] = useState("");
    const [basesInfo, setBasesInfo] = useState([]);

    const [editProvince, setEditProvince] = useState(false);
    const [provinces, setProvinces] = useState([]);
    const [question, setQuestion] = useState("");
    const [editQuestion, setEditQuestion] = useState(false);
    const [allAnswers, setAllAnswers] = useState(["false", ""]);

    const { Option } = Select;
    const { TextArea } = Input;
    const previewPosition = [
        { value: 1, label: "Posición 1" },
        { value: 2, label: "Posición 2" },
        { value: 3, label: "Posición 3" },
        { value: 4, label: "Posición 4" },
        { value: 5, label: "Posición 5" },
    ];

    useEffect(() => {
        cleanState();
    }, []);

    useEffect(() => {
        countDocument("Portada", setItemQuantity);
        setNameCollection(item);
    }, [visible]);

    useEffect(() => {
        FormDisabledForm();
    }, [
        cardTitle,
        // cardText,
        // cardOverline,
        cardButtonText,
        cardActive,
        position,
        pageTitle,
        pageText,
        // pageOverline,
        pageFormTitle,
        pageFormText,
        pageActive,
        cardImage,
        pageImage,
        bases,
        scriptTitle,
        scriptInterest
    ]);

    const FormDisabledForm = () => {
        if (
            cardTitle &&
            pageTitle &&
            position &&
            scriptTitle &&
            scriptInterest &&
            cardImage &&
            pageImage &&
            bases &&
            itemQuantity < 5
        ) {
            setFormDisabled(false);
        } else {
            setFormDisabled(true);
        }
    };

    const cleanState = () => {
        setCardTitle("");
        // setCardText("")
        // setCardOverline("")
        setCardButtonText("");
        setCardActive(true);
        setPosition("");

        setLocation("");
        setDate("");

        setPageTitle("");
        setPageText("");
        // setPageOverline("")
        setPageFormTitle("");
        setPageFormText("");
        setPageActive(true);

        setCardImage(null);
        setCardImageInfo({});
        setCardImagePreview(null);

        setPageImage(null);
        setPageImageInfo({});
        setPageImagePreview(null);

        setBasesInfo([]);
        setBases("");

        setScriptInterest("")
        setScriptTitle("")
    };

    const onSend = () => {
        const obj = {
            cardTitle,
            // cardText,
            // cardOverline,
            cardButtonText,
            cardActive,
            position,
            location,
            date,
            pageTitle,
            pageText,
            // pageOverline,
            pageFormTitle,
            pageFormText,
            pageActive,
            provinces: [],
            answer: allAnswers,
            question: "false",
            bases: bases,
            basesInfo: JSON.stringify(basesInfo),
            cardImage: cardImage,
            cardImageInfo: JSON.stringify(cardImageInfo),
            pageImage: pageImage,
            pageImageInfo: JSON.stringify(pageImageInfo),
            scriptTitle,
            scriptInterest
        };
        if (editProvince && provinces.length) obj.provinces = provinces;
        if (editQuestion) obj.question = question;
        saveItem(obj, nameCollection, setModal, cleanState);
     
    };

    const addCardImage = ({ file, fileList }) => {
        if (fileList.length > 0) {
            if (beforeUpload(file)) {
                setCardImage(file.xhr);
                setCardImageInfo(file);
                setError("");
            } else {
                setError("La imagen cargada es mayor a 2MB");
            }
        }
    };

    const handleCardImagePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        if (file.url) {
            setCardImagePreview(file.url);
        } else {
            setCardImagePreview(file.preview);
        }

        setCardImageVisiblePreview(true);
    };

    const cancelCardImagePreview = () => {
        setCardImageVisiblePreview(false);
    };

    const handleRemoveCardImage = () => {
        setCardImage(null);
        setCardImageInfo(null);
        setCardImagePreview(null);
        setCardImageVisiblePreview(false)(null);
    };

    const addPageImage = ({ file, fileList }) => {
        if (fileList.length > 0) {
            if (beforeUpload(file)) {
                setPageImage(file.xhr);
                setPageImageInfo(file);
                setError("");
            } else {
                setError("La imagen cargada es mayor a 2MB");
            }
        }
    };

    const addBases = ({ file, fileList }) => {
        if (fileList.length > 0) {
            if (beforeUpload2(file)) {
                console.log({ file });
                setBases(file.xhr);
                setBasesInfo(file);
                setError("");
            } else {
                setError("La imagen cargada es mayor a 2MB");
            }
        }
    };

    const handlePageImagePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        if (file.url) {
            setPageImagePreview(file.url);
        } else {
            setPageImagePreview(file.preview);
        }

        setPageImageVisiblePreview(true);
    };

    const CancelPreviewMobile = () => {
        setPageImageVisiblePreview(false);
    };

    const handleRemovePageImage = () => {
        setPageImage(null);
        setPageImageInfo({});
        setPageImagePreview(null);
        setPageImageVisiblePreview(null);
    };

    const handleRemoveFile = () => {
        setBases(null);
        setBasesInfo(null);
    };

    const handleProvinces = (checkedValues) => {
        console.log("checked = ", checkedValues);
        setProvinces(checkedValues);
    };
    const handleInputMap = (val, idx) => {
        let newValue = [...allAnswers];
        newValue[idx] = val;
        setAllAnswers(newValue);
    };

    return (
        <div>
            <div className={"pageHeader modalHeader"}>
                <span className={"title"}>Agregar experiencia</span>
            </div>

            <div className={"pageHeader modalSubHeader"}>
                <span className={"title"}>TARJETA</span>
                <hr />
            </div>
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>TITULO</p>
                    <Form.Item>
                        <Input
                            value={cardTitle}
                            onChange={(e) => setCardTitle(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>TEXTO DEL BOTÓN</p>
                    <Form.Item>
                        <Input
                            value={cardButtonText}
                            onChange={(e) => setCardButtonText(e.target.value)}
                        />
                    </Form.Item>
                </div>
            </div>
            {/* <div className={"modalRow"}>
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>SOBRETÍTULO</p>
          <Form.Item>
            <Input
              value={cardOverline}
              onChange={(e) => setCardOverline(e.target.value)}
            />
          </Form.Item>
        </div>
        <div className={"modalInputContainer lastInput"}>
          <p className={"modalInputLabel"}>TEXTO</p>
          <Form.Item>
            <TextArea
              value={cardText}
              rows={4}
              onChange={(e) => setCardText(e.target.value)}
            />
          </Form.Item>
        </div>
      </div> */}
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>POSICIÓN *</p>
                    <p className={"modalInputSubLabel"}>
                        Posición de la tarjeta
                    </p>
                    <Form.Item>
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Seleccioná una opción"
                            value={"Posición " + position}
                            onChange={(e) => setPosition(e)}
                        >
                            <Option disabled value={""}>
                                {"Seleccioná una opción"}
                            </Option>
                            {previewPosition.map((element, id) => {
                                return (
                                    <Option key={id} value={element.value}>
                                        {element.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <Form.Item>
                        <p className={"modalInputLabel"}>TARJETA ACTIVADA</p>
                        <p className={"modalInputSubLabel"}>
                            Mostrar u ocultar esta tarjeta
                        </p>
                        <Switch
                            checked={cardActive}
                            onChange={() => {
                                setCardActive(!cardActive);
                            }}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className={"pageHeader modalSubHeader"}>
                <span className={"title"}>PÁGINA</span>
                <hr />
            </div>
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>TÍTULO</p>
                    <Form.Item>
                        <Input
                            value={pageTitle}
                            onChange={(e) => setPageTitle(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>TEXTO</p>
                    <Form.Item>
                        <TextArea
                            value={pageText}
                            rows={4}
                            onChange={(e) => setPageText(e.target.value)}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>UBICACIÓN</p>
                    <Form.Item>
                        <Input
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>FECHA Y HORA</p>
                    <Form.Item>
                        <DatePicker
                            size="medium"
                            // showTime={true}
                            placeholder=""
                            showTime={{
                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                            }}
                            value={date ? moment(date) : null}
                            onChange={(date, dateString) => setDate(dateString)}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className={"modalRow"}>
                {/* <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>SOBRETÍTULO</p>
          <Form.Item>
            <Input
              value={pageOverline}
              onChange={(e) => setPageOverline(e.target.value)}
            />
          </Form.Item>
        </div> */}
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>TÍTULO DEL FORMULARIO</p>
                    <Form.Item>
                        <Input
                            value={pageFormTitle}
                            onChange={(e) => setPageFormTitle(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>BASES Y CONDICIONES *</p>
                    <Form.Item>
                        <Upload
                            accept="application/pdf,application/vnd.ms-excel"
                            name="avatar"
                            listType="text"
                            className="avatar-uploader"
                            onRemove={handleRemoveFile}
                            // fileList={basesInfo}
                            onChange={addBases}
                            customRequest={(e) =>
                                upload(e, "Experiencias/Bases")
                            }
                            onPreview={() => window.open(bases)}
                        >
                            {!basesInfo.length > 0 && <PlusOutlined />}
                        </Upload>
                    </Form.Item>
                </div>
            </div>
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>TEXTO DEL FORMULARIO</p>
                    <Form.Item>
                        <TextArea
                            value={pageFormText}
                            rows={4}
                            onChange={(e) => setPageFormText(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <Form.Item>
                        <p className={"modalInputLabel"}>PÁGINA ACTIVADA</p>
                        <p className={"modalInputSubLabel"}>
                            Mostrar u ocultar esta tarjeta (también activa o
                            desactiva el botón de la tarjeta)
                        </p>
                        <Switch
                            checked={pageActive}
                            onChange={() => {
                                setPageActive(!pageActive);
                            }}
                        />
                    </Form.Item>
                </div>
            </div>

            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>IMAGEN TARJETA</p>
                    <Form.Item help="">
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            onPreview={handleCardImagePreview}
                            onRemove={handleRemoveCardImage}
                            customRequest={(e) =>
                                upload(e, "Experiencias/Tarjetas")
                            }
                            // customRequest={upload}
                            onChange={addCardImage}
                        >
                            {!cardImage && <PlusOutlined />}
                        </Upload>
                        <Modal
                            wrapClassName={"wrapContainerModalPreview"}
                            visible={cardImageVisiblePreview}
                            footer={null}
                            onCancel={cancelCardImagePreview}
                        >
                            <img
                                alt="example"
                                style={{ width: "100%" }}
                                src={cardImagePreview}
                            />
                        </Modal>
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>IMAGEN PÁGINA</p>
                    <Form.Item help="">
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            onPreview={handlePageImagePreview}
                            onRemove={handleRemovePageImage}
                            customRequest={(e) =>
                                upload(e, "Experiencias/Páginas")
                            }
                            onChange={addPageImage}
                        >
                            {!pageImage && <PlusOutlined />}
                        </Upload>
                        <Modal
                            wrapClassName={"wrapContainerModalPreview"}
                            visible={pageImageVisiblePreview}
                            footer={null}
                            onCancel={CancelPreviewMobile}
                        >
                            <img
                                alt="example"
                                style={{ width: "100%" }}
                                src={pageImagePreview}
                            />
                        </Modal>
                    </Form.Item>
                </div>
            </div>
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>PREGUNTA (OPCIONAL)</p>
                    {/* <p className={"modalInputSubLabel"}>Por defecto se mostrarán todas las provincias, solamente tocar si se quiere editar.</p> */}
                    <Switch
                        checked={editQuestion}
                        onChange={() => {
                            setEditQuestion(!editQuestion);
                        }}
                    />
                    {editQuestion && (
                        <Input
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                        />
                    )}
                    {allAnswers[0] === "false" ? (
                        <p className={"modalInputSubLabel"}>
                            Toca el switch para editar las opciones.
                        </p>
                    ) : (
                        <p className={"modalInputSubLabel"}>
                            Toca el switch para dejarlo como estaba.
                        </p>
                    )}
                    <Switch
                        checked={allAnswers[0] === "false" ? false : true}
                        onChange={() => {
                            if (allAnswers[0] === "false") {
                                let newArr = [...allAnswers];
                                newArr.splice(0, 1, "true");
                                console.log(newArr);
                                setAllAnswers(newArr);
                            } else {
                                let newArr = [...allAnswers];
                                newArr.splice(0, 1, "false");
                                console.log(newArr);
                                setAllAnswers(newArr);
                            }
                        }}
                    />
                    {allAnswers[0] === "true" &&
                        allAnswers.map((ans, idx) => {
                            if (idx) {
                                return (
                                    <Input
                                        value={allAnswers[idx]}
                                        onChange={(e) =>
                                            handleInputMap(e.target.value, idx)
                                        }
                                    />
                                );
                            }
                        })}
                    <Button
                        onClick={() => {
                            setAllAnswers([...allAnswers, ""]);
                        }}
                    >
                        MÁS
                    </Button>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>PROVINCIAS</p>
                    <p className={"modalInputSubLabel"}>
                        Por defecto se mostrarán todas las provincias, solamente
                        tocar si se quiere editar.
                    </p>
                    <Switch
                        checked={editProvince}
                        onChange={() => {
                            setEditProvince(!editProvince);
                        }}
                    />
                    {editProvince && (
                        <Checkbox.Group
                            options={options}
                            defaultValue={["Apple"]}
                            onChange={handleProvinces}
                        />
                    )}
                </div>
            </div>

            <div className={"pageHeader modalSubHeader"}>
                <span className={"title"}>SCRIPT</span>
                <hr />
            </div>
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>TÍTULO DE CAMPAÑA</p>
                    <Form.Item>
                        <Input
                            value={scriptTitle}
                            onChange={(e) => setScriptTitle(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>ABI INTERESTS</p>
                    <Form.Item>
                        <Input
                            value={scriptInterest}
                            onChange={(e) => setScriptInterest(e.target.value)}
                        />
                    </Form.Item>
                </div>
            </div>

            <div className={"ContainerMessageCantSlides"}>
                {itemQuantity == 5 && (
                    <p className={"messageCantSlide"}>
                        Hay 5 items. Debe borrar uno para crear otro nuevo
                    </p>
                )}
                {<p className={"messageCantSlide"}>{error}</p>}
            </div>

            <Button
                onClick={onSend}
                disabled={formDisabled}
                className={"sendButton"}
                type="primary"
                block
            >
                CREAR
            </Button>
        </div>
    );
};

export default DashboardNewExperiencesSingleItem;
