import React from "react"
import { Menu, Layout, Drawer } from "antd"
const { Sider, Content } = Layout
const { SubMenu } = Menu

const DashboardDrawerMobile = ({ setItemOpen, mobileOpen, setMobileOpen }) => {
  const handleClick = (e) => {
    setItemOpen(e.key)
  }

  return (
    <Drawer
      title="Dashboard"
      placement="left"
      closable={true}
      onClose={() => {
        setMobileOpen(false)
      }}
      visible={mobileOpen}
    >
      <Menu
        onClick={handleClick}
        mode="inline"
        defaultSelectedKeys={["Novedades"]}
        style={{ height: "100%" }}
      >
        <Menu.Item key="Novedades">Novedades</Menu.Item>
        <Menu.Item key="Experiencias">Experiencias</Menu.Item>
        {/* <SubMenu title="Experiencias">
          <Menu.Item key="ExperienciasPrincipal">Textos principales</Menu.Item>
          <Menu.Item key="Experiencias">Tarjetas y páginas</Menu.Item>
        </SubMenu> */}
        <Menu.Item key="Campañas">Campañas</Menu.Item>
      </Menu>
    </Drawer>
  )
}

export default DashboardDrawerMobile
