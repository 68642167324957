import React, { useState, useEffect } from "react"
import { Input, Form, Upload, Modal, Button, Select, Switch } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { getBase64, upload, saveItem } from "../../utils/functionsNew"
import { countDocument, beforeUpload } from "../../utils/functionsList"
import "../../styles/dashboard-edit-or-new-item.css"

const DashboardNewCarouselSingleItem = ({ visible, item, setModal }) => {
  const [title, setTitle] = useState("")
  const [paragraph, setParagraph] = useState("")
  const [textButton, setTexButton] = useState("")
  const [linkButton, setLinkButton] = useState("")
  const [position, setPosition] = useState("")
  const [active, setActive] = useState("")

  const [formDisabled, setFormDisabled] = useState(true)
  const [nameCollection, setNameCollection] = useState(null)
  const [itemQuantity, setItemQuantity] = useState(null)
  const [error, setError] = useState("")

  // image desktop
  const [ImageDesktop, setImageDesktop] = useState(null)
  const [InfoImageDesktop, setInfoImageDesktop] = useState({})
  const [PreviewImageDesktop, setPreviewImageDesktop] = useState(null)
  const [PreviewVisibleDesktop, setPreviewVisibleDesktop] = useState(null)
  // image mobile
  const [ImageMobile, setImageMobile] = useState(null)
  const [InfoImageMobile, setInfoImageMobile] = useState({})
  const [PreviewImageMobile, setPreviewImageMobile] = useState(null)
  const [PreviewVisibleMobile, setPreviewVisibleMobile] = useState(null)

  const { Option } = Select
  const { TextArea } = Input
  const previewPosition = [
    { value: 1, label: "Posición 1" },
    { value: 2, label: "Posición 2" },
    { value: 3, label: "Posición 3" },
    { value: 4, label: "Posición 4" },
    { value: 5, label: "Posición 5" },
  ]

  useEffect(() => {
    cleanState()
  }, [])

  useEffect(() => {
    countDocument("Novedades", setItemQuantity)
    setNameCollection(item)
    cleanState()
  }, [visible])

  useEffect(() => {
    FormDisabledForm()
  }, [
    title,
    paragraph,
    position,
    active,
    ImageDesktop,
    ImageMobile,
    textButton,
    linkButton,
  ])

  const FormDisabledForm = () => {
    if (title && position && ImageDesktop && ImageMobile && itemQuantity < 5) {
      setFormDisabled(false)
    } else {
      setFormDisabled(true)
    }
  }

  const cleanState = () => {
    setTitle("")
    setParagraph("")
    setPosition("")
    setActive(true)
    setTexButton("")
    setLinkButton("")
    setImageDesktop(null)
    setInfoImageDesktop({})
    setPreviewImageDesktop(null)
    setImageMobile(null)
    setInfoImageMobile({})
    setPreviewImageMobile(null)
  }

  const onSend = () => {
    const obj = {
      title,
      paragraph,
      position,
      textButton,
      linkButton,
      active,
      imageDesktop: ImageDesktop,
      infoImageDesktop: JSON.stringify(InfoImageDesktop),
      imageMobile: ImageMobile,
      infoImageMobile: JSON.stringify(InfoImageMobile),
    }
    saveItem(obj, nameCollection, setModal, cleanState)
  }

  // IMAGE DESKTOP
  const AddImageDesktop = ({ file, fileList }) => {
    if (fileList.length > 0) {
      if (beforeUpload(file)) {
        setImageDesktop(file.xhr)
        setInfoImageDesktop(file)
        setError("")
      } else {
        setError("La imagen cargada es mayor a 2MB")
      }
    }
  }

  const handlePreviewImageDesktop = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    if (file.url) {
      setPreviewImageDesktop(file.url)
    } else {
      setPreviewImageDesktop(file.preview)
    }

    setPreviewVisibleDesktop(true)
  }

  const CancelPreviewDesktop = () => {
    setPreviewVisibleDesktop(false)
  }

  const handleRemoveImageDesktop = () => {
    setImageDesktop(null)
    setInfoImageDesktop(null)
    setPreviewImageDesktop(null)
    setPreviewVisibleDesktop(null)
  }

  // IMAGE MOBILE
  const AddImageMobile = ({ file, fileList }) => {
    if (fileList.length > 0) {
      if (beforeUpload(file)) {
        setImageMobile(file.xhr)
        setInfoImageMobile(file)
        setError("")
      } else {
        setError("La imagen cargada es mayor a 2MB")
      }
    }
  }

  const handlePreviewImageMobile = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    if (file.url) {
      setPreviewImageMobile(file.url)
    } else {
      setPreviewImageMobile(file.preview)
    }

    setPreviewVisibleMobile(true)
  }

  const CancelPreviewMobile = () => {
    setPreviewVisibleMobile(false)
  }

  const handleRemoveImageMobile = () => {
    setImageMobile(null)
    setInfoImageMobile({})
    setPreviewImageMobile(null)
    setPreviewVisibleMobile(null)
  }

  return (
    <div>
      <div className={"pageHeader modalHeader"}>
        <span className={"title"}>Agregar una novedad</span>
      </div>

      <div className={"modalRow"}>
        {/* title */}
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>TÍTULO</p>
          <Form.Item /*validateStatus={this.state.validateStatus}*/ help="">
            <Input value={title} onChange={(e) => setTitle(e.target.value)} />
          </Form.Item>
        </div>
        {/* paragraph */}
        <div className={"modalInputContainer lastInput"}>
          <p className={"modalInputLabel"}>PÁRRAFO</p>
          <Form.Item /*validateStatus={this.state.validateStatus}*/ help="">
            <TextArea
              value={paragraph}
              rows={4}
              onChange={(e) => setParagraph(e.target.value)}
            />
          </Form.Item>
        </div>
      </div>

      {/* buttons */}
      <div className={"modalRow"}>
        {/* text button */}
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>TEXTO DEL BOTÓN</p>
          <Form.Item /*validateStatus={this.state.validateStatus}*/ help="">
            <Input
              value={textButton}
              onChange={(e) => setTexButton(e.target.value)}
            />
          </Form.Item>
        </div>
        {/* link button */}
        <div className={"modalInputContainer lastInput"}>
          <p className={"modalInputLabel"}>LINK DEL BOTÓN</p>
          <Form.Item /*validateStatus={this.state.validateStatus}*/ help="">
            <Input
              value={linkButton}
              onChange={(e) => setLinkButton(e.target.value)}
            />
          </Form.Item>
        </div>
      </div>

      {/* previewPosition */}
      <div className={"modalRow"}>
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>POSICIÓN</p>
          <p className={"modalInputSubLabel"}>
            Posición de este item en el slider/carrusel.
          </p>
          <Form.Item
            /*validateStatus={this.state.validateStatus}*/
            help=""
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Seleccioná una posición"
              value={position}
              onChange={(e) => setPosition(e)}
            >
              <Option disabled value={""}>
                {"Seleccioná una opción"}
              </Option>
              {previewPosition.map((element, id) => {
                return (
                  <Option key={element.id} value={element.value}>
                    {element.label}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </div>
        <div className={"modalInputContainer lastInput"}>
          <Form.Item help="">
            <p className={"modalInputLabel"}>ACTIVADO</p>
            <p className={"modalInputSubLabel"}>Mostrar u ocultar este item</p>
            <Switch
              checked={active}
              onChange={() => {
                setActive(!active)
              }}
            />
          </Form.Item>
        </div>
      </div>

      {/* images */}
      <div className={"modalRow"}>
        {/* ImageDesktop */}
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>IMAGEN ESCRITORIO</p>
          <Form.Item help="">
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              onPreview={handlePreviewImageDesktop}
              onRemove={handleRemoveImageDesktop}
              customRequest={(e) => upload(e, "Novedades/Desktop")}
              // customRequest={upload}
              onChange={AddImageDesktop}
            >
              {!ImageDesktop && <PlusOutlined />}
            </Upload>
            <Modal
              wrapClassName={"wrapContainerModalPreview"}
              visible={PreviewVisibleDesktop}
              footer={null}
              onCancel={CancelPreviewDesktop}
            >
              <img
                alt="example"
                style={{ width: "100%" }}
                src={PreviewImageDesktop}
              />
            </Modal>
          </Form.Item>
        </div>
        {/* imageMobile */}
        <div className={"modalInputContainer lastInput"}>
          <p className={"modalInputLabel"}>IMAGEN MÓVIL</p>
          <Form.Item help="">
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              onPreview={handlePreviewImageMobile}
              onRemove={handleRemoveImageMobile}
              customRequest={(e) => upload(e, "Novedades/Mobile")}
              onChange={AddImageMobile}
            >
              {!ImageMobile && <PlusOutlined />}
            </Upload>
            <Modal
              wrapClassName={"wrapContainerModalPreview"}
              visible={PreviewVisibleMobile}
              footer={null}
              onCancel={CancelPreviewMobile}
            >
              <img
                alt="example"
                style={{ width: "100%" }}
                src={PreviewImageMobile}
              />
            </Modal>
          </Form.Item>
        </div>
      </div>

      <div className={"ContainerMessageCantSlides"}>
        {itemQuantity == 5 && (
          <p className={"messageCantSlide"}>
            Hay 5 items. Debe borrar uno para crear otro nuevo
          </p>
        )}
        {<p className={"messageCantSlide"}>{error}</p>}
      </div>

      <Button
        onClick={onSend}
        disabled={formDisabled}
        className={"sendButton"}
        type="primary"
        block
      >
        CREAR
      </Button>
    </div>
  )
}

export default DashboardNewCarouselSingleItem
