import React, { useState, useEffect } from "react";
import {
    Input,
    Form,
    Select,
    Upload,
    Modal,
    Button,
    Switch,
    Checkbox,
    DatePicker,
} from "antd";
import moment from "moment";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
    updateItem,
    customUpload,
    loadInfoImage,
    loadInfoFile,
} from "../../utils/functionsList";
import "../../styles/dashboard-edit-or-new-item.css";
import { element } from "prop-types";

const { Option } = Select;
const { TextArea } = Input;

const options = [
    { label: "Buenos Aires - CABA", value: "Buenos Aires - CABA" },
    {
        label: "Buenos Aires - Mar del Plata",
        value: "Buenos Aires - Mar del Plata",
    },
    {
        label: "Buenos Aires - Chapadmalal",
        value: "Buenos Aires - Chapadmalal",
    },
    { label: "Buenos Aires - Pinamar", value: "Buenos Aires - Pinamar" },
    { label: "Buenos Aires", value: "Buenos Aires" },
    { label: "Catamarca", value: "Catamarca" },
    { label: "Chaco", value: "Chaco" },
    { label: "Chubut", value: "Chubut" },
    {
        label: "Ciudad Autónoma de Buenos Aires",
        value: "Ciudad Autónoma de Buenos Aires",
    },
    { label: "Córdoba", value: "Córdoba" },
    { label: "Corrientes", value: "Corrientes" },
    { label: "Entre Ríos", value: "Entre Ríos" },
    { label: "Formosa", value: "Formosa" },
    { label: "Jujuy", value: "Jujuy" },
    { label: "La Pampa", value: "La Pampa" },
    { label: "La Plata", value: "La Plata" },
    { label: "La Rioja", value: "La Rioja" },
    { label: "Mendoza", value: "Mendoza" },
    { label: "Misiones", value: "Misiones" },
    { label: "Neuquén", value: "Neuquén" },
    { label: "Paraná", value: "Paraná" },
    { label: "Posadas", value: "Posadas" },
    { label: "Rawson", value: "Rawson" },
    { label: "Resistencia", value: "Resistencia" },
    { label: "Río Gallegos", value: "Río Gallegos" },
    { label: "Río Negro", value: "Río Negro" },
    { label: "Rosario", value: "Rosario" },
    { label: "Salta", value: "Salta" },
    { label: "San Carlos de Bariloche", value: "San Carlos de Bariloche" },
    {
        label: "San Fernando del Valle de Catamarca",
        value: "San Fernando del Valle de Catamarca",
    },
    { label: "San Juan", value: "San Juan" },
    { label: "San Luis", value: "San Luis" },
    { label: "San Martin de los Andes", value: "San Martin de los Andes" },
    { label: "San Miguel de Tucumán", value: "San Miguel de Tucumán" },
    { label: "San Salvador de Jujuy", value: "San Salvador de Jujuy" },
    { label: "Santa Cruz", value: "Santa Cruz" },
    { label: "Santa Fe", value: "Santa Fe" },
    { label: "Santa Rosa", value: "Santa Rosa" },
    { label: "Santiago del Estero", value: "Santiago del Estero" },
    { label: "Tierra del Fuego", value: "Tierra del Fuego" },
    { label: "Tucumán", value: "Tucumán" },
    { label: "Ushuaia", value: "Ushuaia" },
    { label: "Viedma", value: "Viedma" },
    { label: "Villa La Angostura", value: "Villa La Angostura" },
];

const DashboardExperiencesSingleItemEdit = ({
    selectedItem,
    setModal,
    item,
}) => {
    const [cardTitle, setCardTitle] = useState("");
    const [scriptTitle, setScriptTitle] = useState("");
    const [scriptInterest, setScriptInterest] = useState("");
    // const [cardText, setCardText] = useState("")
    // const [cardOverline, setCardOverline] = useState("")
    const [cardButtonText, setCardButtonText] = useState("");
    const [cardActive, setCardActive] = useState(false);
    const [position, setPosition] = useState("");

    const [location, setLocation] = useState("");
    const [date, setDate] = useState("");

    const [pageTitle, setPageTitle] = useState("");
    const [pageText, setPageText] = useState("");
    // const [pageOverline, setPageOverline] = useState("")
    const [pageFormTitle, setPageFormTitle] = useState("");
    const [pageFormText, setPageFormText] = useState("");
    const [pageActive, setPageActive] = useState(false);

    const [cardImageInfo, setCardImageInfo] = useState([]);
    const [pageImageInfo, setPageImageInfo] = useState([]);
    const [previewImage, setPreviewImage] = useState("");
    const [visibleImagePreview, setVisibleImagePreview] = useState(false);

    const [nameCollection, setNameCollection] = useState("");
    const [error, setError] = useState("");
    const [formDisabled, setFormDisabled] = useState(true);
    const previewPosition = [
        { value: 1, label: "Posición 1" },
        { value: 2, label: "Posición 2" },
        { value: 3, label: "Posición 3" },
        { value: 4, label: "Posición 4" },
        { value: 5, label: "Posición 5" },
    ];

    const [bases, setBases] = useState("");
    const [basesInfo, setBasesInfo] = useState([]);

    const [editProvince, setEditProvince] = useState(false);
    const [editAnswer, setEditAnswer] = useState(false);
    const [provinces, setProvinces] = useState([]);
    const [editableProvinces, setEditedProvinces] = useState();
    const [question, setQuestion] = useState("");
    const [allAnswers, setAllAnswers] = useState([]);

    // console.log("Bases Info!!", basesInfo)

    useEffect(() => {
        loadData(selectedItem);
        setFormDisabled(true);
    }, [selectedItem]);

    useEffect(() => {
        validateSubmit();
    }, [
        cardTitle,
        // cardText,
        // cardOverline,
        cardButtonText,
        cardActive,
        position,
        pageTitle,
        pageText,
        // pageOverline,
        pageFormTitle,
        pageFormText,
        pageActive,
        cardImageInfo,
        pageImageInfo,
    ]);

    const loadData = (element) => {
        let dataBasesInfo = [];
        if (element.basesInfo) dataBasesInfo = JSON.parse(element.basesInfo);
        const dataCardImageInfo = JSON.parse(element.cardImageInfo);
        const dataPageImageInfo = JSON.parse(element.pageImageInfo);
        setCardTitle(element.cardTitle);
        // setCardText(element.cardText)
        // setCardOverline(element.cardOverline)
        setCardButtonText(element.cardButtonText);
        setCardActive(element.cardActive);
        setPosition(element.position);
        setLocation(element.location);
        setDate(element.date);
        setProvinces(element.provinces);
        setEditedProvinces(element.provinces);
        setPageTitle(element.pageTitle);
        setPageText(element.pageText);
        // setPageOverline(element.pageOverline)
        setPageFormTitle(element.pageFormTitle);
        setPageFormText(element.pageFormText);
        setPageActive(element.pageActive);
        setQuestion(element.question);
        setAllAnswers(element.answer);
        setNameCollection(item);
        setScriptTitle(element.scriptTitle);
        setScriptInterest(element.scriptInterest);
        //allow preview image
        setCardImageInfo([dataCardImageInfo]);
        setPageImageInfo([dataPageImageInfo]);
        if (element.basesInfo) setBasesInfo([dataBasesInfo]);
        // if(element.provinces)setEditProvince(true)
        setBases(element.bases);
    };

    const validateSubmit = () => {
        if (
            cardTitle &&
            position &&
            cardImageInfo.length > 0 &&
            pageImageInfo.length > 0
        ) {
            setFormDisabled(false);
        } else {
            setFormDisabled(true);
        }
    };

    const onEdit = () => {
        const obj = {
            id: selectedItem.id,
            cardTitle,
            // cardText,
            // cardOverline,
            cardButtonText,
            cardActive,
            position,
            location,
            date,
            pageTitle,
            pageText,
            // pageOverline,
            pageFormTitle,
            pageFormText,
            pageActive,
            question,
            answer: allAnswers,
            provinces: editProvince ? editableProvinces : provinces,
            bases: basesInfo[0].xhr,
            basesInfo: JSON.stringify(basesInfo[0]),
            cardImage: cardImageInfo[0].xhr,
            cardImageInfo: JSON.stringify(cardImageInfo[0]),
            pageImage: pageImageInfo[0].xhr,
            pageImageInfo: JSON.stringify(pageImageInfo[0]),
            scriptTitle,
            scriptInterest,
        };
        if (!question.length) obj.question = "false";
        updateItem(obj, nameCollection, setModal);
    };

    // upload images
    const handlerImage = ({ file }, type) => {
        if (file.status === "removed") {
            // remove
            if (type === "card") {
                setCardImageInfo([]);
            } else {
                setPageImageInfo([]);
            }
        } else {
            //load image

            if (type === "card") {
                loadInfoImage(file, setCardImageInfo, setError);
            } else {
                loadInfoImage(file, setPageImageInfo, setError);
            }
        }
    };

    // upload file
    const handlerFile = ({ file }, type) => {
        if (file.status === "removed") {
            // remove
            setBasesInfo([]);
        } else {
            //load file
            loadInfoFile(file, setBasesInfo, setError);
        }
    };

    const handlePreview = async (file) => {
        setPreviewImage(file.xhr);
        setVisibleImagePreview(true);
    };

    const handleProvinces = (checkedValues) => {
        if (checkedValues.length === options.length) {
            setEditedProvinces(false);
        } else {
            setEditedProvinces(checkedValues);
        }
    };

    const handleInputMap = (val, idx) => {
        let newValue = [...allAnswers];
        newValue[idx] = val;
        setAllAnswers(newValue);
    };

    return (
        <div>
            <div className={"pageHeader modalHeader"}>
                <span className={"title"}>Editar experiencia</span>
            </div>
            <div className={"pageHeader modalSubHeader"}>
                <span className={"title"}>TARJETA</span>
                <hr />
            </div>

            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>TITULO</p>
                    <Form.Item>
                        <Input
                            value={cardTitle}
                            onChange={(e) => setCardTitle(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>TEXTO DEL BOTÓN</p>
                    <Form.Item>
                        <Input
                            value={cardButtonText}
                            onChange={(e) => setCardButtonText(e.target.value)}
                        />
                    </Form.Item>
                </div>
            </div>
            {/* <div className={"modalRow"}>
        <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>SOBRETÍTULO</p>
          <Form.Item>
            <Input
              value={cardOverline}
              onChange={(e) => setCardOverline(e.target.value)}
            />
          </Form.Item>
        </div>
        <div className={"modalInputContainer lastInput"}>
          <p className={"modalInputLabel"}>TEXTO</p>
          <Form.Item>
            <TextArea
              value={cardText}
              rows={4}
              onChange={(e) => setCardText(e.target.value)}
            />
          </Form.Item>
        </div>
      </div> */}
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>POSICIÓN *</p>
                    <p className={"modalInputSubLabel"}>
                        Posición de la tarjeta
                    </p>
                    <Form.Item>
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Seleccioná una opción"
                            value={"Posición " + position}
                            onChange={(e) => setPosition(e)}
                        >
                            <Option disabled value={""}>
                                {"Seleccioná una opción"}
                            </Option>
                            {previewPosition.map((element, id) => {
                                return (
                                    <Option key={id} value={element.value}>
                                        {element.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <Form.Item>
                        <p className={"modalInputLabel"}>TARJETA ACTIVADA</p>
                        <p className={"modalInputSubLabel"}>
                            Mostrar u ocultar esta tarjeta
                        </p>
                        <Switch
                            checked={cardActive}
                            onChange={() => {
                                setCardActive(!cardActive);
                            }}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className={"pageHeader modalSubHeader"}>
                <span className={"title"}>PÁGINA</span>
                <hr />
            </div>
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>TÍTULO</p>
                    <Form.Item>
                        <Input
                            value={pageTitle}
                            onChange={(e) => setPageTitle(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>TEXTO</p>
                    <Form.Item>
                        <TextArea
                            value={pageText}
                            rows={4}
                            onChange={(e) => setPageText(e.target.value)}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>UBICACIÓN</p>
                    <Form.Item>
                        <Input
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>FECHA Y HORA</p>
                    <Form.Item>
                        <DatePicker
                            size="medium"
                            placeholder=""
                            showTime={{
                                defaultValue: moment("00:00:00", "HH:mm:ss"),
                            }}
                            value={date ? moment(date) : null}
                            onChange={(date, dateString) => setDate(dateString)}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className={"modalRow"}>
                {/* <div className={"modalInputContainer"}>
          <p className={"modalInputLabel"}>SOBRETÍTULO</p>
          <Form.Item>
            <Input
              value={pageOverline}
              onChange={(e) => setPageOverline(e.target.value)}
            />
          </Form.Item>
        </div> */}
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>TÍTULO DEL FORMULARIO</p>
                    <Form.Item>
                        <Input
                            value={pageFormTitle}
                            onChange={(e) => setPageFormTitle(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>BASES Y CONDICIONES *</p>
                    <Form.Item>
                        <Upload
                            accept="application/pdf,application/vnd.ms-excel"
                            name="avatar"
                            listType="text"
                            className="avatar-uploader"
                            fileList={basesInfo}
                            onChange={(e) => handlerFile(e, "bases")}
                            customRequest={(e) =>
                                customUpload(e, "Experiencias/Bases")
                            }
                            onPreview={() => window.open(basesInfo[0].xhr)}
                        >
                            {!basesInfo.length > 0 && <PlusOutlined />}
                        </Upload>
                    </Form.Item>
                </div>
            </div>
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>TEXTO DEL FORMULARIO</p>
                    <Form.Item>
                        <TextArea
                            value={pageFormText}
                            rows={4}
                            onChange={(e) => setPageFormText(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <Form.Item>
                        <p className={"modalInputLabel"}>PÁGINA ACTIVADA</p>
                        <p className={"modalInputSubLabel"}>
                            Mostrar u ocultar esta tarjeta (también activa o
                            desactiva el botón de la tarjeta)
                        </p>
                        <Switch
                            checked={pageActive}
                            onChange={() => {
                                setPageActive(!pageActive);
                            }}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>IMAGEN DE LA TARJETA *</p>
                    <Form.Item>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            fileList={cardImageInfo}
                            onChange={(e) => handlerImage(e, "card")}
                            customRequest={(e) =>
                                customUpload(e, "Experiencias/Tarjetas")
                            }
                            onPreview={handlePreview}
                        >
                            {!cardImageInfo.length > 0 && <PlusOutlined />}
                        </Upload>
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>IMAGEN DE LA PÁGINA *</p>
                    <Form.Item>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            fileList={pageImageInfo}
                            onChange={(e) => handlerImage(e, "page")}
                            customRequest={(e) =>
                                customUpload(e, "Experiencias/Páginas")
                            }
                            onPreview={handlePreview}
                        >
                            {!pageImageInfo.length > 0 && <PlusOutlined />}
                        </Upload>
                    </Form.Item>
                </div>
            </div>
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>PREGUNTA</p>
                    <Form.Item>
                        <Input
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                        />
                    </Form.Item>

                    {allAnswers[0] === "false" ? (
                        <p className={"modalInputSubLabel"}>
                            Toca el switch para editar las opciones.
                        </p>
                    ) : (
                        <p className={"modalInputSubLabel"}>
                            Toca el switch para dejarlo como estaba.
                        </p>
                    )}
                    <Switch
                        checked={allAnswers[0] === "false" ? false : true}
                        onChange={() => {
                            if (allAnswers[0] === "false") {
                                let newArr = [...allAnswers];
                                newArr.splice(0, 1, "true");
                                setAllAnswers(newArr);
                            } else {
                                let newArr = [...allAnswers];
                                newArr.splice(0, 1, "false");
                                setAllAnswers(newArr);
                            }
                        }}
                    />
                    {allAnswers[0] === "true" &&
                        allAnswers.map((ans, idx) => {
                            if (idx) {
                                return (
                                    <Input
                                        value={allAnswers[idx]}
                                        onChange={(e) =>
                                            handleInputMap(e.target.value, idx)
                                        }
                                    />
                                );
                            }
                        })}
                    <Button
                        onClick={() => {
                            setAllAnswers([...allAnswers, ""]);
                        }}
                    >
                        MÁS
                    </Button>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>PROVINCIAS</p>
                    {!editProvince ? (
                        <p className={"modalInputSubLabel"}>
                            Toca el switch para editar las provincias.
                        </p>
                    ) : (
                        <p className={"modalInputSubLabel"}>
                            Toca el switch para dejarlo como estaba.
                        </p>
                    )}
                    <Switch
                        checked={editProvince}
                        onChange={() => {
                            setEditProvince(!editProvince);
                        }}
                    />
                    {editProvince && (
                        <Checkbox.Group
                            options={options}
                            defaultValue={editableProvinces}
                            onChange={handleProvinces}
                        />
                    )}
                </div>
            </div>

            <div className={"pageHeader modalSubHeader"}>
                <span className={"title"}>SCRIPT</span>
                <hr />
            </div>
            <div className={"modalRow"}>
                <div className={"modalInputContainer"}>
                    <p className={"modalInputLabel"}>TÍTULO DE CAMPAÑA</p>
                    <Form.Item>
                        <Input
                            value={scriptTitle}
                            onChange={(e) => setScriptTitle(e.target.value)}
                        />
                    </Form.Item>
                </div>
                <div className={"modalInputContainer lastInput"}>
                    <p className={"modalInputLabel"}>ABI INTERESTS</p>
                    <Form.Item>
                        <Input
                            value={scriptInterest}
                            onChange={(e) => setScriptInterest(e.target.value)}
                        />
                    </Form.Item>
                </div>
            </div>

            <Modal
                wrapClassName={"wrapContainerModalPreview"}
                visible={visibleImagePreview}
                footer={null}
                onCancel={(e) => setVisibleImagePreview(false)}
            >
                <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                />
            </Modal>

            <div className={"ContainerMessageCantSlides"}>
                <p className={"messageCantSlide"}>{error}</p>
            </div>
            <Button
                onClick={onEdit}
                disabled={formDisabled}
                className={"sendButton"}
                type="primary"
                block
            >
                EDITAR
            </Button>
        </div>
    );
};

export default DashboardExperiencesSingleItemEdit;
