import React from "react"
import { List, Popconfirm } from "antd"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { deleteItem, editItem } from "../../utils/functionsList"
import styles from "../../styles/dashboard-list.module.scss"

const DashboardCampaignsSingleItem = ({
  item,
  nameCollection,
  setSelectedItem,
  setModalEdit,
}) => {
  const IconText = ({ type, text, item }) => (
    <span onClick={() => editItem(item, setSelectedItem, setModalEdit)}>
      {type === "edit" && <EditOutlined style={{ marginRight: 8 }} />}
      {text}
    </span>
  )

  function cancel(e) {
    console.log(e)
  }

  const IconTextDelete = ({ type, text, item }) => (
    <Popconfirm
      title="Eliminar campaña?"
      onConfirm={() => deleteItem(item, nameCollection)}
      onCancel={cancel}
      okText="Si"
      cancelText="No"
    >
      <span>
        {type === "delete" && <DeleteOutlined style={{ marginRight: 8 }} />}
        {text}{" "}
      </span>
    </Popconfirm>
  )

  return (
    <List.Item
      className={styles.ItemLink}
      key={item.id}
      actions={[
        <IconText
          item={item}
          type="edit"
          text="Editar"
          key="list-vertical-edit"
        />,
        <IconTextDelete
          item={item}
          type="delete"
          text="Eliminar"
          key="list-vertical-delete"
        />,
      ]}
      // extra={
      //   <span>
      //     <img
      //       className={styles.shopImageElement}
      //       alt="logo"
      //       src={item.image}
      //     />
      //   </span>
      // }
    >
      <p className={styles.nameDescription}>
        Titulo: <span>{item.title}</span>
      </p>
      {/* <p className={styles.nameDescription}>
        Párrafo: <span>{item.text}</span>
      </p>
      <p className={styles.nameDescription}>
        Sobretítulo: <span>{item.overline}</span>
      </p> */}
      <p className={styles.nameDescription}>
        Posición: <span>{item.position}</span>
      </p>
      <p className={styles.nameDescription}>
        Link del video: <span>{item.video}</span>
      </p>
      <p className={styles.nameDescription}>
        URL de la página:{" "}
        <span>{`https://stellaartois.com.ar/campaigns/${item.title
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/ +/g, "-")
          .toLowerCase()}`}</span>
      </p>
      <p className={styles.nameDescription}>
        Activada: <span>{item.active ? "Sí" : "No"}</span>{" "}
      </p>
    </List.Item>
  )
}

export default DashboardCampaignsSingleItem
